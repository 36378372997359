/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';

import toastr from '@lib/toastr';
import { useCreateServiceDocumentSplits } from '@src/hooks/queries/accounts_payable/service_document_splits';
import { useUpdateTransactionServiceDocumentDoNotLearn } from '@src/hooks/queries/transaction_service_documents';
import { TID } from '@src/types/common';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import { ICategorySplit } from '@src/components/common_v2/category_splits_modal/schema';
import { useSplitCategory } from '@src/components/reconciliation_center/all_transactions/sidebar/transactionType/use_split_category';
import VendorInput from '@src/components/reconciliation_center/journal_entries/header/vendor_input';
import CategorySplitsField from '@src/components/reconciliation_center/match_documents/add_adjustment/category_splits_field/index';
import Spinner from '@src/components/ui/spinner';
import MutationStatus from '@src/components/utils/mutation_status';

import DocytAiLearnToggle from './docyt_ai_learn_toggle';
import NoDocumentNeeded from './no_document_needed';
import NoPushQbo from './no_push_qbo';
import TransactionTypeSelector from './transaction_type_selector';
import useTxnDoc from './use_txn_doc';

import styles from '../../styles.module.scss';

interface IExpenseProps {
  businessId: TID;
  rowData: ITransactionServiceDocument;
  Verify: (boolean: boolean) => void;
  setRowData: (rowData: ITransactionServiceDocument) => void;
  vendor: string;
  setVendor: React.Dispatch<React.SetStateAction<string>>;
}
interface IVendorOption {
  value: string;
  label?: string;
  helper?: string;
}

const Expense = ({
  businessId,
  rowData,
  Verify,
  setRowData,
  vendor,
  setVendor,
}: IExpenseProps) => {
  const [categorySplits, setCategorySplits] = useState<ICategorySplit[]>([]);

  const {
    updateFields,
    handleCreateDocumentNote,
    handleNdnChange,
    commonProps,
  } = useTxnDoc({ rowData, setRowData });

  const {
    isSubmitting, isDisabled, doNotLearn, noDocumentNeeded, documentNote, noPushQbo,
  } = commonProps;

  const [successMsg, setSuccessMsg] = useState<string | undefined>(undefined);

  const updateTransactionServiceDocumentDoNotLearn = useUpdateTransactionServiceDocumentDoNotLearn();
  const { mutateAsync: updateTransactionServiceDocumentDoNotLearnMutate } = updateTransactionServiceDocumentDoNotLearn;

  const createServiceDocumentSplits = useCreateServiceDocumentSplits();

  const { processCategories, getCategorySplits, handleCategorySplitsChange } = useSplitCategory({
    rowData,
    businessId,
    setCategorySplits,
    setSuccessMsg,
  });

  const objectToUpdate = {
    id:                 rowData.id,
    doNotLearn,
    noPushQbo,
    user_vendor_id:     vendor,
    vendor_id:          vendor,
    noDocumentRequired: noDocumentNeeded,
  };

  const handleVendorChange = async (item: IVendorOption): Promise<void> => {
    try {
      const result = await updateTransactionServiceDocumentDoNotLearnMutate({
        ...objectToUpdate,
        user_vendor_id: item?.value,
        vendor_id:      item?.value,
      });

      // Build category splits based on the vendor change
      if (result) {
        let returnCategorySplits: ICategorySplit[] = [];
        const { transactionServiceDocument } = result;

        const categories = transactionServiceDocument.category.split(';');

        returnCategorySplits = await processCategories(categories);

        setCategorySplits(returnCategorySplits);
        setVendor(item?.value || '');
      }
    } catch (error) {
      const errorMessage = (error as Error)?.message || 'An unknown error occurred';
      toastr.error(`Failed to update vendor: ${errorMessage}`, 'Error');
    }
  };

  useEffect(() => {
    const loadCategorySplits = async () => {
      const splits = await getCategorySplits();
      setCategorySplits(splits);
    };

    loadCategorySplits();
  }, [getCategorySplits, setCategorySplits]);

  useEffect(() => {
    let isVerify = false;

    const isVendorEmpty = !vendor || vendor === 'undefined' || vendor === '';
    const isCategorySplitsEmpty = !categorySplits.length;
    const isDocumentNoteRequired = noDocumentNeeded && !documentNote;

    if (isVendorEmpty || isCategorySplitsEmpty || isDocumentNoteRequired) {
      isVerify = true;
    }

    Verify(isVerify);
  }, [vendor, categorySplits, noDocumentNeeded, documentNote, Verify]);

  return (
    <div key={ rowData.id }>
      {isSubmitting && <Spinner />}
      <MutationStatus mutation={ createServiceDocumentSplits } successMessage={ successMsg } />
      <div className={ styles['sidebar-type-config'] }>
        <TransactionTypeSelector rowData={ rowData } setRowData={ setRowData } />
        <div className={ styles['sidebar-data'] }>
          <span>Vendor*</span>
          <div style={ { width: '280px' } }>
            <VendorInput
              businessId={ businessId }
              disabled={ isDisabled }
              handleSelected={ handleVendorChange }
              value={ vendor }
            />
          </div>
        </div>
        <div className={ styles['sidebar-data'] }>
          <CategorySplitsField
            adjustmentAmount={ rowData.amount }
            isBusinessReadonly={ false }
            isReadonly={ isDisabled }
            label="Category*"
            summaryTitle="Invoice"
            value={ categorySplits }
            onChange={ handleCategorySplitsChange }
          />
        </div>
        <DocytAiLearnToggle
          checked={ !doNotLearn }
          isDisabled={ isDisabled }
          onChange={ (checked) => updateFields({ doNotLearn: !checked }) }
        />
      </div>
      <div className={ styles['sidebar-type-config'] }>
        <NoDocumentNeeded
          key={ documentNote }
          documentNote={ documentNote }
          isDisabled={ isDisabled }
          noDocumentNeeded={ noDocumentNeeded }
          rowData={ rowData }
          onDocumentNoteChange={ handleCreateDocumentNote }
          onNdnChange={ handleNdnChange }
        />
        <NoPushQbo
          isDisabled={ isDisabled }
          noPushQbo={ noPushQbo }
          onChange={ (checked) => updateFields({ noPushQbo: checked }) }
        />
      </div>
    </div>
  );
};

export default Expense;
