import React, { useMemo, useState } from 'react';

import debounce from 'lodash/debounce';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import styles from '@src/components/reconciliation_center/all_transactions/styles.module.scss';

interface Props {
  rowData: ITransactionServiceDocument;
  isDisabled: boolean;
  noDocumentNeeded: boolean;
  documentNote?: string;
  onNdnChange: (checked: boolean) => void;
  onDocumentNoteChange: (note: string) => void;
}

const NoDocumentNeeded = ({
  rowData,
  isDisabled,
  noDocumentNeeded,
  documentNote,
  onNdnChange,
  onDocumentNoteChange,
}: Props) => {
  const business = useBusinessContext();
  const [tempNote, setTempNote] = useState<string>(documentNote || '');

  const debouncedNoteChange = useMemo(
    () => debounce((note: string) => {
      if (note) onDocumentNoteChange(note);
    }, 500),
    [onDocumentNoteChange],
  );

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newNote = e.target.value;
    setTempNote(newNote);
    debouncedNoteChange(newNote);
  };

  return (
    <>
      <div className={ styles['sidebar-data-checkbox'] }>
        <input
          checked={ noDocumentNeeded }
          className={ isDisabled ? styles['disabled-checkbox'] : '' }
          id="no-document-needed"
          type="checkbox"
          onChange={ (e) => !isDisabled && onNdnChange(e.target.checked) }
        />
        <label htmlFor="no-document-needed">No document needed</label>
        <a
          href={ `/businesses/${business.id}/reconciliation_center/settings` }
          style={ { color: '#2196F3', marginLeft: '8px', position: 'absolute', right: '26px' } }
        >
          Docyt AI Settings
        </a>
      </div>
      {noDocumentNeeded && (
      <div>
        <textarea
          key={ rowData.documentId }
          disabled={ isDisabled }
          placeholder="Add note"
          rows={ 2 }
          style={ {
            borderColor: tempNote || isDisabled ? 'initial' : 'red',
            width:       '100%',
          } }
          value={ tempNote }
          onChange={ handleTextareaChange }
        />
        {!tempNote && !isDisabled && (
        <div
          style={ {
            color:      'red',
            marginTop:  '4px',
            display:    'flex',
            alignItems: 'center',
            gap:        '4px',
          } }
        >
          <i className="fa fa-warning" />
          Note is required.
        </div>
        )}
      </div>
      )}
    </>
  );
};

export default NoDocumentNeeded;
