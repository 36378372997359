import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useUpdateTsdFlaggedState } from '@src/hooks/queries/transaction_service_documents';
import { documentPath } from '@src/routes';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import {
  FileTextIcon,
  FolderOpenIcon,
  TimesIcon,
  CheckCircleIcon,
  PlusIcon,
  EnvelopeIcon,
} from '@src/components/utils/fa_icons';
import MutationStatus from '@src/components/utils/mutation_status';

type TAllTransactionsListActionsProps = {
  document: ITransactionServiceDocument,
}

const AllTransactionsListActions = ({
  document,
}: TAllTransactionsListActionsProps) => {
  const updateState = useUpdateTsdFlaggedState();
  const business = useBusinessContext();
  const { mutateAsync } = updateState;

  const handleAction = useCallback((eventName: string) => {
    mutateAsync({ ...document, flaggedState: eventName === 'closeIssue' ? null : eventName });
  }, [document, mutateAsync]);

  const handleResolvedOrOpen = useCallback(() => {
    handleAction(document.flaggedState === 'open' ? 'resolved' : 'open');
  }, [handleAction, document.flaggedState]);

  const handleCloseIssue = useCallback(() => {
    handleAction('closeIssue');
  }, [handleAction]);

  const handleAddDocument = useCallback(() => {
    window.Docyt.vent.trigger('related:document:add', document);
  }, [document]);

  const handleNoDocumentNeeded = useCallback(() => {
    // @ts-ignore
    window.Docyt.vent.trigger('transaction:document:note:add', document);
  }, [document]);

  const handleSendMessage = useCallback(() => {
    window.Docyt.vent.trigger('pagination:enable', false);
    window.Docyt.currentAdvisor.set({ is_empty_page: false });
    window.Docyt.currentAdvisor.set({ is_individual_page: true });

    // @ts-ignore
    const documentModalBuilder = new window.Docyt.AdvisorHomeApp.Documents.Builder({
      is_modal: true,
    });
    documentModalBuilder.showDocumentChat(document.documentId);
  }, [document.documentId]);

  return (
    <>
      { !document?.noDocumentRequired && (
        <ActionsDropdown.Action
          icon={ <PlusIcon fontSize={ 20 } /> }
          title="Add Document"
          onSelect={ handleAddDocument }
        />
      ) }
      <ActionsDropdown.Action
        icon={ <EnvelopeIcon fontSize={ 20 } /> }
        title="Send a Message"
        onSelect={ handleSendMessage }
      />
      <ActionsDropdown.LinkAction
        href={ documentPath(document.documentId) }
        icon={ <FileTextIcon fontSize={ 20 } variant="o" /> }
        title="View Transaction Detail"
      />
      {document?.flaggedState ? (
        <>
          <ActionsDropdown.Action
            disabled={
              document.transactionDate < business.reconciliationStartDate
            }
            icon={
              document.flaggedState === 'open' ? (
                <CheckCircleIcon fontSize={ 20 } variant="o" />
              ) : (
                <FolderOpenIcon fontSize={ 20 } variant="o" />
              )
            }
            title={
              document.flaggedState === 'open'
                ? 'Mark as Reviewed'
                : 'Re-open Issue'
            }
            onSelect={ handleResolvedOrOpen }
          />
          <ActionsDropdown.Action
            disabled={document.transactionDate < business.reconciliationStartDate}
            icon={ <TimesIcon fontSize={ 20 } /> }
            title="Close Issue"
            onSelect={ handleCloseIssue }
          />
        </>
      ) : (
        <ActionsDropdown.Action
          icon={ <i className="icon icon-docyt-support font-20" /> }
          title="Flag for Client Review"
          onSelect={ () => handleAction('open') }
        />
      )}
      {!document?.noDocumentRequired
        && (!document?.hasDocuments || document?.state !== 'verified') && (
          <ActionsDropdown.Action
            icon={ <i className="icon icon-mark-as-matched font-20" /> }
            title="No Document Needed"
            onSelect={ handleNoDocumentNeeded }
          />
      )}
      <MutationStatus mutation={ updateState } />
    </>
  );
};

export default AllTransactionsListActions;
