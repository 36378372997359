/* eslint-disable max-len */
import React, { FC, useCallback, useMemo } from 'react';

import { GroupBase, MenuListProps, MultiValue, MultiValueProps, SingleValue, StylesConfig, components } from 'react-select';
import AsyncSelect from 'react-select/async';

import { useGetSelectOptions } from '@src/hooks/queries/select_options';
import { getSelectOptions } from '@src/requests/select_options';
import { TResource } from '@src/types/select_options';

import Wrapper from '@src/components/ui_v2/form/fields/form_field_wrapper';
import { TOption } from '@src/components/ui_v2/inputs';

const SELECT_ALL_LABEL = 'Select all';
const DESELECT_ALL_LABEL = 'Deselect all';

interface ResourceSelectProps {
  resource: TResource,
  error?: string,
  multiple?: boolean,
  label?: string,
  value?: number[] | string[],
  placeholder?: string,
  query?: Record<string, any>,
  onChange: (value: number[] | number | string[] | string | undefined) => void;
  isClearable?: boolean;
}

const STYLES: StylesConfig<TOption> = {
  control: (provided) => ({
    ...provided,
    'border':    '1px solid rgba(15, 44, 188, 0.08)',
    'boxShadow': 'none',
    '&:hover':   {
      border:  '1px solid rgba(77, 78, 255, 0.3)',
      outline: 'none',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingTop:    0,
    paddingBottom: 0,
  }),
};

const Option = (props: any) => {
  const { label, isSelected } = props;
  return (
    <div>
      <components.Option { ...props }>
        <input
          checked={ isSelected }
          style={ { marginRight: '8px' } }
          type="checkbox"
          onChange={ () => null }
        />

        {' '}
        <label>{label}</label>
      </components.Option>
    </div>
  );
};

const MultiValueComponent = <IsMulti extends boolean, Group extends GroupBase<TOption>>(props: MultiValueProps<TOption, IsMulti, Group>) => {
  const { getValue, index } = props;
  const selectedCount = getValue().length;

  if (index > 0 || selectedCount === 0) return null;

  return (
    <components.MultiValueContainer { ...props }>
      { selectedCount }
      { ' ' }
      selected
    </components.MultiValueContainer>
  );
};

const MenuListComponent = (props: MenuListProps<TOption, boolean, GroupBase<TOption>>) => {
  const { options, setValue, getValue, selectProps } = props;
  const normalizedOptions = options as TOption[];

  if (!selectProps.isMulti) {
    return <components.MenuList { ...props } />;
  }

  const selected = new Set(getValue().map((it) => it.value.toString()));
  const isAllSelected = () => normalizedOptions.every((it) => {
    const value = it.value.toString();
    return selected.has(value);
  });

  let label = isAllSelected() ? DESELECT_ALL_LABEL : SELECT_ALL_LABEL;

  const handleSelectAll = () => {
    if (isAllSelected()) {
      setValue([], 'deselect-option');
      label = SELECT_ALL_LABEL;
    } else {
      setValue(normalizedOptions, 'select-option');
      label = DESELECT_ALL_LABEL;
    }
  };

  return (
    <>
      <div
        role="button"
        style={ { padding: '8px 12px', cursor: 'pointer', fontWeight: 'bold', borderBottom: '1px solid #ccc' } }
        tabIndex={ 0 }
        onClick={ handleSelectAll }
        onMouseDown={ (e) => { e.preventDefault(); e.stopPropagation(); } }
      >
        {label}
        {' '}
        (
        {options.length}
        )
      </div>
      <components.MenuList { ...props } options={ options } />
    </>
  );
};

const ResourceSelect: FC<ResourceSelectProps> = ({
  value,
  resource,
  multiple = false,
  placeholder = 'Select...',
  error,
  label,
  query,
  onChange,
  isClearable = true,
}) => {
  const wrapperProps = { label, error };
  const memoizedOnChange = useCallback(onChange, [onChange]);

  const handleChange = useCallback((newValue: SingleValue<TOption> | MultiValue<TOption>) => {
    const normalizedValue = multiple ? Array.from(newValue as MultiValue<TOption>).map((it) => it.value) : (newValue as SingleValue<TOption>)?.value;
    memoizedOnChange(normalizedValue);
  }, [multiple, memoizedOnChange]);

  const loadOptions = useCallback(async (q: string): Promise<TOption[]> => {
    const data = await getSelectOptions({ q, resource, query });
    const collection = data?.collection ?? [];
    const options = collection.map((it) => ({ label: it.text, value: it.value }));
    return options;
  }, [resource, query]);

  const selectedValue = Array.isArray(value) ? value.join(',') : value;
  const selectedOptions = useGetSelectOptions({ resource, query, selected: selectedValue });
  const selectedItem = useMemo(() => {
    if (selectedOptions.isLoading || !selectedValue || selectedValue.length === 0) return undefined;

    const options = selectedOptions.data?.collection ?? [];

    if (multiple) {
      return options.map((it) => ({ label: it.text, value: it.value }));
    }

    const selectedOption = options[0];
    if (!selectedOption) return undefined;

    return {
      label: selectedOption.text,
      value: selectedOption.value,
    };
  }, [selectedOptions.isLoading, selectedOptions.data?.collection, selectedValue, multiple]);

  return (
    <Wrapper { ...wrapperProps }>
      <AsyncSelect
        cacheOptions
        defaultOptions
        closeMenuOnSelect={ !multiple }
        components={ {
          Option,
          MultiValue: multiple ? MultiValueComponent : undefined,
          MenuList:   MenuListComponent,
        } }
        hideSelectedOptions={ false }
        isClearable={ isClearable }
        isLoading={ selectedOptions.isLoading }
        isMulti={ multiple }
        loadOptions={ loadOptions }
        placeholder={ placeholder }
        styles={ STYLES }
        value={ selectedItem }
        onChange={ handleChange }
      />
    </Wrapper>
  );
};

export default React.memo(ResourceSelect);
