import { omit } from 'lodash';
import { useMutation, useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { createUseGetInfiniteCollection } from '@src/hooks/queries/infinite_collection_queries';
import { IGetChartOfAccountResponse } from '@src/requests/chart_of_accounts';
import { TID } from '@src/types/common';
import { ISimpleChartOfAccount } from '@src/types/simple_chart_of_accounts';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiDelete, apiGet, apiPost, apiPut } from './helpers';

interface IGetSimpleChartOfAccountsResponse {
  meta: {
    totalCount: number,
  },
  collection: ISimpleChartOfAccount[],
}

interface IGetSimpleChartOfAccountsParams {}

// eslint-disable-next-line max-len
const getSimpleChartOfAccounts = (params: IGetSimpleChartOfAccountsParams): Promise<IGetSimpleChartOfAccountsResponse> => {
  const url = '/api/v1/simple_chart_of_accounts';

  return apiGet(url, { ...underscoreKeys(params) }).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      meta:       cdata.meta,
      collection: cdata.simpleChartOfAccounts,
    } as IGetSimpleChartOfAccountsResponse;
  });
};

const useGetSimpleChartOfAccounts = createUseGetInfiniteCollection<
  ISimpleChartOfAccount,
  IGetSimpleChartOfAccountsParams,
  IGetSimpleChartOfAccountsResponse
>({
  queryKey: QueryKey.simpleChartOfAccounts,
  request:  getSimpleChartOfAccounts,
});

interface ICreateChartOfAccountParams {
  businessId: number;
  accType: string;
  accTypeName: string;
  subType: string;
  subTypeCode: string;
  name: string;
  number?: string;
  parentId?: string;
  parentName?: string;
  line?: number;
  children?: ICreateChartOfAccountParams[];
}

const createChartOfAccount = (
  params: ICreateChartOfAccountParams,
): Promise<void> => {
  return apiPost('/api/v1/chart_of_accounts', underscoreKeys(params));
};

const useCreateChartOfAccount = () => {
  return useMutation<void, Error, ICreateChartOfAccountParams>(createChartOfAccount);
};

interface IGetChartOfAccountParams {
  id: TID,
  businessId: TID,
  includeMappedClasses: boolean,
}

const getChartOfAccount = (
  params: IGetChartOfAccountParams,
): Promise<IGetChartOfAccountResponse> => {
  return apiGet(
    `/api/v1/chart_of_accounts/${params.id}`,
    underscoreKeys({ ...omit(params, 'id') }),
  ).then((data) => {
    return {
      chartOfAccount: camelizeKeys(data.chart_of_account),
    } as IGetChartOfAccountResponse;
  });
};

const useGetChartOfAccount = (params: IGetChartOfAccountParams) => {
  return useQuery<IGetChartOfAccountResponse, Error>(
    [QueryKey.simpleChartOfAccountById, params],
    () => getChartOfAccount(params),
  );
};

interface IUpdateChartOfAccountParams extends ICreateChartOfAccountParams {
  id: TID
}

const updateChartOfAccount = (
  params: IUpdateChartOfAccountParams,
): Promise<void> => {
  return apiPut(`/api/v1/chart_of_accounts/${params.id}`, underscoreKeys(params));
};

const useUpdateChartOfAccount = () => {
  return useMutation<void, Error, IUpdateChartOfAccountParams>(updateChartOfAccount);
};

interface IDeleteChartOfAccountParams {
  id: TID,
  businessId: TID,
}

const deleteChartOfAccount = (
  params: IDeleteChartOfAccountParams,
): Promise<void> => {
  return apiDelete(`/api/web/v1/business_chart_of_accounts/${params.id}`, underscoreKeys(params));
};

const useDeleteChartOfAccount = () => {
  return useMutation<void, Error, IDeleteChartOfAccountParams>(deleteChartOfAccount);
};

export {
  IGetSimpleChartOfAccountsResponse,
  getSimpleChartOfAccounts,
  useGetSimpleChartOfAccounts,
  ICreateChartOfAccountParams,
  useCreateChartOfAccount,
  useGetChartOfAccount,
  useUpdateChartOfAccount,
  useDeleteChartOfAccount,
};
